import { Modal } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import Loading from '../../../../../components/Common/Loading/Loading'
import { ActionType, StatusAPI } from '../../../../../enum'
import { AttCommon, Contact } from '../../../../../models'
import { clearCurrentDestinataire } from '../../../../../redux/reducers/destinataireSlice'
import { getCurrentFiltersText, renderNull } from '../../../../../utils'
import { convertInterface } from '../../../../../utils/attendu/convertAttCommon'
import './CommonModal.scss'

const DestinataireViewOnly = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const dispatch = useDispatch()

  const { currentDestinataire } = useSelector(
    (state: RootState) => state.destinataire
  )

  const columnsAdresses: ColumnsType<AttCommon> = [
    {
      title: <div className="form-label">Complément</div>,
      dataIndex: 'complement',
      key: 'complement',
      width: '12%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Adresse</div>,
      dataIndex: 'adresse',
      key: `adresse`,
      width: '28%',
      className: 'modal-column',
      render: (_, row) => renderNull(row.addresse as string),
    },
    {
      title: <div className="form-label">Code postal</div>,
      dataIndex: 'code_postal',
      key: 'code_postal',
      width: '18%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Ville</div>,
      dataIndex: 'ville',
      key: 'ville',
      width: '12%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Pays</div>,
      dataIndex: 'pays',
      key: 'pays',
      width: '12%',
      className: 'modal-column',
      render: (_, row) =>
        `${renderNull(row.pays)} (${renderNull(row.iso as string)})`,
    },
    {
      title: <div className="form-label">GLN</div>,
      dataIndex: 'gln',
      key: 'gln',
      width: '18%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: <div className="form-label">Nom</div>,
      dataIndex: 'nom',
      key: 'nom',
      width: '28%',
      className: 'modal-column',
      render: (el: string) => renderNull(el),
    },
    {
      title: <div className="form-label">Tel</div>,
      dataIndex: 'tel',
      key: 'tel',
      width: '21%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Mobile</div>,
      dataIndex: 'mobile',
      key: 'mobile',
      width: '21%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Email</div>,
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
  ]

  const handleClose = () => {
    dispatch(clearCurrentDestinataire())
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(
        `/gestion-de-stock/commande?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    }
  }

  return (
    <Modal
      destroyOnClose
      title={
        <div className="flex text-primary text-xl font-bold items-center border-0 border-b border-b-gray3 border-solid -mt-5">
          <p className="mr-4" style={{ fontSize: '28px' }}>
            Destinataire
          </p>
          <p className="font-normal text-2xl my-4">
            (n° {currentDestinataire.destinataire?.code})
          </p>
        </div>
      }
      open={true}
      footer={null}
      onCancel={handleClose}
      className="modal border-solid border-secondary rounded-xl"
      bodyStyle={{ overflowY: 'auto', height: 'calc(100vh - 330px)' }}
    >
      <div className="w-full">
        <>
          {currentDestinataire.status === StatusAPI.calling &&
          currentDestinataire.action === ActionType.GET_BY_ID ? (
            <Loading />
          ) : (
            <>
              <div className="bg-gray2 flex items-center px-4 py-3 mb-4 rounded-lg">
                <>
                  <p className="text-gray font-semibold mr-3 text-base">Nom</p>
                  {/* <p
                    className="w-64 px-3 py-1 bg-blocked_input border border-border_input border-solid
      rounded-lg italic font-light text-base "
                  >
                    {currentDestinataire.destinataire?.nom}
                  </p> */}
                  <div className="common-modal-nom">
                    {currentDestinataire.destinataire?.nom}
                  </div>
                </>
              </div>
              <div className="bg-gray2 py-1 px-4 rounded-lg mb-4 ">
                <p className="text-primary font-bold text-lg border-0 border-b border-b-gray3 border-solid pb-4">
                  Adresses
                </p>
                <Table
                  rowKey="id"
                  columns={columnsAdresses}
                  dataSource={
                    currentDestinataire?.destinataire && [
                      convertInterface(
                        currentDestinataire?.destinataire.addresse[0]
                      ),
                    ]
                  }
                  pagination={false}
                  className="custom-common-modal mb-4 mt-7"
                />
              </div>
              <div className="bg-gray2 pt-1 pb-4 px-4 rounded-lg mb-4">
                <p className="text-primary font-bold text-lg border-0 border-b border-b-gray3 border-solid pb-4">
                  Contact
                </p>
                <Table
                  rowKey="id"
                  columns={columnsContact}
                  dataSource={
                    currentDestinataire.destinataire && [
                      currentDestinataire.destinataire.contact[0],
                    ]
                  }
                  pagination={false}
                  className="custom-common-modal mb-4 mt-7"
                />
              </div>
              <hr className="border-0 border-b border-b-gray3 border-solid pt-4" />
            </>
          )}
        </>
      </div>
    </Modal>
  )
}

export default DestinataireViewOnly
